@import 'src/theme/color';

.componentWrapper {
  background: $color-white;

  main {
    min-height: 100vh;
  }

  .content {
    padding: 30px 20px;
    background-color: $color-gray-background;
  }
}
